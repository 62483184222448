import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
// import {VelocityTransitionGroup} from 'velocity-react'
// ReactDOM.render(<App />, document.getElementById('root'));
// import { Link, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { scroller } from 'react-scroll'
import infoBackImg from './images/info_back.jpg'
import serviceBackImg from './images/service_back.jpg'
import sccessBackImg from './images/access_back.jpg'
import recBackImg from './images/rec_back.jpg'

import {useDebounce} from 'use-debounce'

class TopHeader extends Component {
    // React.initializeTouchEvents(true)
    constructor(props){
        super(props)
        this.state = {
            isHover_n: false,
            isHover_e: false,
            isHover_x: false,
            isHover_t: false
        }
    }

    changeClass(e){
        // console.log(e)
        switch (e){
            case 'n_open':
                this.setState({isHover_n: true})
                // this.go_info('service_content')
                break
            case 'n_open_m':
                if(this.state.isHover_n === false){
                    this.setState({isHover_n: true})
                    // this.go_info('service_content')
                }else {
                    this.setState({isHover_n: false})
                }
                break
            case 'n_close':
                console.log('閉じた')
                this.setState({isHover_n: false})
                // this.go_info('service_content')
                break

            case 'e_open':
                this.setState({isHover_e: true})
                break
            case 'e_open_m':
                if(this.state.isHover_e === false){
                    this.setState({isHover_e: true})
                }else {
                    this.setState({isHover_e: false})
                }
                break
            case 'e_close':
                this.setState({isHover_e: false})
                break

            case 'x_open':
                this.setState({isHover_x: true})
                break
            case 'x_open_m':
                if(this.state.isHover_x === false){
                    this.setState({isHover_x: true})
                }else {
                    this.setState({isHover_x: false})
                }
                break
            case 'x_close':
                this.setState({isHover_x: false})
                break

            case 't_open':
                this.setState({isHover_t: true})
                break
            case 't_open_m':
                if(this.state.isHover_t === false){
                    this.setState({isHover_t: true})
                }else {
                    this.setState({isHover_t: false})
                }
                break
            case 't_close':
                this.setState({isHover_t: false})
                break

            default:

        }
    }

    go_info(e) {
        scroller.scrollTo(e, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
            offset: -100
        })
    }


    render() {
        console.log('nに反応:' + this.state.isHover_n)

        var n_class = ''
        var e_class = ''
        var x_class = ''
        var t_class = ''
        if(this.state.isHover_n === true){
            e_class = ' mini'
            x_class = ' mini'
            t_class = ' mini'
        }
        else if(this.state.isHover_e === true){
            n_class = ' mini'
            x_class = ' mini'
            t_class = ' mini'
        }
        else if(this.state.isHover_x === true){
            n_class = ' mini'
            e_class = ' mini'
            t_class = ' mini'
        }
        else if(this.state.isHover_t === true){
            n_class = ' mini'
            e_class = ' mini'
            x_class = ' mini'
        }


        return (
            <header className="App-header" name="top_end">
                <section className={'top_header'}>
                    <div className={'top_box_outer clearfix'}>
                        <div className={this.state.isHover_n === true ?'top_box_inner wide_max': 'top_box_inner'+n_class} id={'top_box_1'}>
                            <div className={'back_image_box backBox1'}>
                                <img className={this.state.isHover_n === true ?'back_img back_img_show': 'back_img'} src={serviceBackImg} alt={'業務案内のバックイメージ'} />
                            </div>
                            <img className={'logo_single test'} src={`${process.env.PUBLIC_URL}/images/logo_n.png`} alt={'logo'} />
                            <div className={'button_box'}
                                 onClick={()=>this.go_info('service_content')}
                                 onMouseEnter={()=>this.changeClass('n_open')}
                                 onMouseLeave={()=>this.changeClass('n_close')}
                                 onTouchStart={()=>this.changeClass('n_open_m')}
                                 onTouchCancel={()=>this.changeClass('n_close')}
                            >&nbsp;</div>
                            <div className={this.state.isHover_n === true ?'info_title show': 'info_title'}>
                                <h2>Service<span>業務案内</span></h2>
                            </div>
                            <button className={'mobile_bt'}><span className="glyphicon glyphicon-triangle-bottom" aria-hidden="true"></span></button>
                        </div>
                        <div className={this.state.isHover_e === true ?'top_box_inner wide_max': 'top_box_inner'+e_class} id={'top_box_2'}>
                            <div className={'back_image_box backBox2'}>
                                <img className={this.state.isHover_e === true ?'back_img back_img_show': 'back_img'} src={sccessBackImg} alt={'アクセスのバックイメージ'} />
                            </div>
                            <img className={'logo_single test2'} src={`${process.env.PUBLIC_URL}/images/logo_e.png`} alt={'logo'} />
                            <div className={'button_box'} onClick={()=>this.go_info('access_content')} onMouseEnter={()=>this.changeClass('e_open')} onMouseLeave={()=>this.changeClass('e_close')} onTouchStart={()=>this.changeClass('e_open_m')} onTouchCancel={()=>this.changeClass('e_close')}>&nbsp;</div>
                            <div className={this.state.isHover_e === true ?'info_title show': 'info_title'}>
                                <h2>Access<span>アクセス</span></h2>
                            </div>
                            <button className={'mobile_bt'}><span className="glyphicon glyphicon-triangle-bottom" aria-hidden="true"></span></button>
                        </div>
                        <div className={this.state.isHover_x === true ?'top_box_inner wide_max': 'top_box_inner'+x_class} id={'top_box_3'}>
                            <div className={'back_image_box backBox3'}>
                                <img className={this.state.isHover_x === true ?'back_img back_img_show': 'back_img'} src={recBackImg} alt={'採用情報のバックイメージ'} />
                            </div>
                            <img className={'logo_single test3'} src={`${process.env.PUBLIC_URL}/images/logo_x.png`} alt={'logo'} />
                            <div className={'button_box'} onClick={()=>this.go_info('rec_content')} onMouseEnter={()=>this.changeClass('x_open')} onMouseLeave={()=>this.changeClass('x_close')} onTouchStart={()=>this.changeClass('x_open_m')} onTouchCancel={()=>this.changeClass('x_close')}>&nbsp;</div>
                            <div className={this.state.isHover_x === true ?'info_title show': 'info_title'}>
                                <h2>Recruit<span>採用情報</span></h2>
                            </div>
                            <button className={'mobile_bt'}><span className="glyphicon glyphicon-triangle-bottom" aria-hidden="true"></span></button>
                        </div>
                        <div className={this.state.isHover_t === true ?'top_box_inner wide_max': 'top_box_inner'+t_class} id={'top_box_4'}>
                            <div className={'back_image_box backBox4'}>
                                <img className={this.state.isHover_t === true ?'back_img back_img_show': 'back_img'} src={infoBackImg} alt={'会社概要のバックイメージ'} />
                            </div>
                            <img className={'logo_single test4'} src={`${process.env.PUBLIC_URL}/images/logo_t.png`} alt={'logo'} />
                            <div className={'button_box'} onClick={()=>this.go_info('info_content')} onMouseEnter={()=>this.changeClass('t_open')} onMouseLeave={()=>this.changeClass('t_close')} onTouchStart={()=>this.changeClass('t_open_m')} onTouchCancel={()=>this.changeClass('t_close')}>&nbsp;</div>
                            <div className={this.state.isHover_t === true ?'info_title show': 'info_title'}>
                                <h2>Information<span>会社概要</span></h2>
                            </div>
                            <button className={'mobile_bt'}><span className="glyphicon glyphicon-triangle-bottom" aria-hidden="true"></span></button>
                        </div>
                    </div>
                </section>
            </header>
        )
    }
}
export default TopHeader
