import React, { Component } from 'react';
import { scroller } from 'react-scroll'

class TopHeader extends Component {
    constructor(props){
        super(props)
        this.state = {
        }
    }


    go_info(e) {
        console.log('クリックしたぜ'+ e)
        this.props.scrollOn(e)
        scroller.scrollTo(e, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
            offset: -100
        })
    }


    render() {


        return (
            <header className="App-header" name="top_end">
                <section className={'top_header'}>
                    <div className={'top_box_outer clearfix'}>
                        <div className={'nav_box'} id={'go_n'} onClick={()=>this.go_info('service_content')}>
                            <h2>Service<span>業務案内</span></h2>
                        </div>
                        <div className={'nav_box'} id={'go_e'} onClick={()=>this.go_info('access_content')}>
                            <h2>Access<span>アクセス</span></h2>
                        </div>
                        <div className={'nav_box'} id={'go_x'} onClick={()=>this.go_info('rec_content')}>
                            <h2>Recruit<span>採用情報</span></h2>
                        </div>
                        <div className={'nav_box'} id={'go_t'} onClick={()=>this.go_info('info_content')}>
                            <h2>Information<span>会社概要</span></h2>
                        </div>
                    </div>
                </section>
            </header>
        )
    }
}
export default TopHeader
