import React from 'react'
import {
    Button,
    // Popover,
    // Tooltip,
    Modal,
    // OverlayTrigger
} from 'react-bootstrap'

export default class ModalA extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.state = {
            show: false,
            consent: true
        };
    }

    handleClose() {
        this.setState({ show: false });
        this.props.updateConsent()
        // this.props.consented(this.state.consent)
    }

    handleShow() {
        this.setState({ show: true });
    }

    render() {

        return (
            <div className={'modal_content'}>
                <Button type="submit" bsStyle="warning" bsSize="small" onClick={this.handleShow}>
                    プライバシーポリシー確認
                </Button>

                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>プライバシーポリシー</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            お預かりした個人情報は、個人情報保護法に基づき管理致します。
                            お問い合わせの際は下記の「個人情報保護方針」をご確認ください。
                        </p>
                        <h4>個人情報保護方針</h4>
                        <p>
                            <strong>個人情報の利用目的</strong><br />お問い合わせへの回答にご利用させていただく場合があります。
                        </p>
                        <p>
                            ここで得られた個人情報は本人の同意無しに、上記の目的以外では利用いたしません。<br />
                            法令に基づく場合を除き、本人の同意無しに第三者に対しデータを開示・提供することはいたしません。
                        </p>
                        <p>
                            本人からの請求があれば情報を開示いたします。<br />
                            公開された個人情報が事実と異なる場合、訂正や削除に応じます。<br />
                            その他、保有する個人情報の取扱に関して適用される法令、国が定める指針及びその他の規範を遵守いたします。
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle={'danger'} onClick={this.handleClose}>同意します</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}