import React from 'react';

import { scroller } from 'react-scroll'
// import sample_1 from './images/service_sample_1.jpg'
import sample_2 from './images/service_sample_2.jpg'
// import sample_3 from './images/service_sample_3.jpg'
import sample_4 from './images/service_sample_4.jpg'
// import TaiyoLogo from './images/taiyo_logo.jpg'

import img15397641 from './images/factory/15397641.jpg'
import img20091318 from './images/factory/20091318.jpg'
import img40710083 from './images/factory/40710083.jpg'

import img13850328 from './images/denki/13850328.jpg'
import img16792693 from './images/denki/16792693.jpg'
import img23790753 from './images/denki/23790753.jpg'

//todo:bootstrap mod
import {
    Button,
    // ButtonToolbar,
    Grid,
    Row,
    Col,
    Glyphicon,
    Breadcrumb
} from 'react-bootstrap'
import {Link} from 'react-router-dom'

export default class RecPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show_form: true,
            show_rec: false
        }
    }


    go_info(e) {
        scroller.scrollTo(e, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
            offset: -100
        })
    }

    componentDidMount() {
        this.go_info('top_end')
    }

    render() {

        return (
            <div className={'wrapper'} id={'top_end'}>
                <div className={'background_image'}>&nbsp;</div>
                <Grid id={'main_content_rec'}>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Top</Breadcrumb.Item>
                        <Breadcrumb.Item active>採用情報</Breadcrumb.Item>
                    </Breadcrumb>

                    <section className={'content'}>
                        <div className={'service_content content_area'} name="service_content">
                            <h2 className={'content_title'}>Recruit <small>採用情報</small></h2>
                            <div className='area_content_box'>
                                <h3 className={'sub_title'}>採用情報詳細</h3>
                                <Row>
                                    <Col sm={4} className={'img_box'}>
                                        <h3 className={'rec_cat'}>&nbsp;</h3>
                                        <img src={img40710083} alt={'会社概要イメージ画像'} />
                                        <img src={img20091318} alt={'会社概要イメージ画像'} />
                                    </Col>
                                    <Col sm={8} className={'img_box'}>
                                        <section className={'rec_pr'}>
                                            <ul className={'list-group'}>
                                                <h3 className={'rec_cat'}>募集内容【工場内技術者】</h3>
                                                <li className={'list-group-item'}>
                                                    <h4>雇用形態</h4>
                                                    <p>正社員</p>
                                                </li>
                                                <li className={'list-group-item'}>
                                                    <h4>仕事内容及びアピールポイント</h4>
                                                    <p>週休2日制(土日祝)の仕事<br />
                                                        事業拡大につき未経験者歓迎、経験者は優遇致します！</p>
                                                    <p>
                                                        工場内での業務用コンベアの組み立て及び電気・配線作業等を担当していただきます。<br />
                                                        仕事内容は丁寧に指導しますので、未経験でもご安心ください。
                                                    </p>
                                                    <p>
                                                        資格取得支援有 手に職がつく！<br />
                                                        玉掛け、クレーン、フォークリフト、溶接などの資格をお持ちの方<br />
                                                        製造の経験がある方歓迎!
                                                    </p>
                                                </li>
                                                <li className={'list-group-item'}>
                                                    <h4>資格</h4>
                                                    <p>学歴不問、経験者・有資格者は優遇致します。</p>
                                                </li>
                                                <li className={'list-group-item'}>
                                                    <h4>勤務地</h4>
                                                    <p>大阪府茨木市</p>
                                                    <p>滋賀県蒲生郡日野町</p>
                                                </li>
                                                <li className={'list-group-item'}>
                                                    <h4>待遇・福利厚生</h4>
                                                    <p>月給250,000円〜</p>
                                                    <p>各種資格取得全額支給、家賃補助制度、通勤手当有、資格手当有、労災完備、生活支援制度有</p>
                                                    <p>研修期間３ヶ月～６ヶ月有(左の期間は有期社員となります)</p>
                                                </li>
                                            </ul>
                                        </section>
                                        <div className={'access_button'}>
                                            <Link to={`${process.env.PUBLIC_URL}/form`}><Button bsStyle="warning"> お問い合わせ <Glyphicon glyph={'glyphicon glyphicon-arrow-right'}/></Button></Link>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={4} className={'img_box'}>
                                        <h3 className={'rec_cat'}>&nbsp;</h3>
                                        <img src={img13850328} alt={'会社概要イメージ画像'} />
                                        <img src={img16792693} alt={'会社概要イメージ画像'} />
                                    </Col>
                                    <Col sm={8} className={'img_box'}>
                                        <section className={'rec_pr'}>
                                            <ul className={'list-group'}>
                                                <h3 className={'rec_cat'}>募集内容【電気工事作業者】</h3>
                                                <li className={'list-group-item'}>
                                                    <h4>雇用形態</h4>
                                                    <p>正社員</p>
                                                </li>
                                                <li className={'list-group-item'}>
                                                    <h4>仕事内容及びアピールポイント</h4>
                                                    <p>週休2日制(土日祝)の仕事<br />
                                                        事業拡大につき未経験者歓迎、経験者は優遇致します！</p>
                                                    <p>
                                                        現場内の電気工事業全般を行って頂きます。弊社では、電気工の資格を弊社負担で取得頂けます！<br />
                                                        ＜2種電気工事士＞合格の為の技術講習も社内で行っています！現在電気工事士のニーズがすごく高まっており、<br />
                                                        電気工事の技術や知識を身に着けることで安定した職を手に入れることができます。
                                                    </p>
                                                    <p>
                                                        私生活でも役立つ電気の知識を一緒に身につけましょう。</p>
                                                </li>
                                                <li className={'list-group-item'}>
                                                    <h4>資格</h4>
                                                    <p>学歴不問、経験者・電気工事士１種、２種(なくても可)、高所者業者運転資格者は優遇致します。</p>
                                                </li>
                                                <li className={'list-group-item'}>
                                                    <h4>勤務地</h4>
                                                    <p>大阪市平野区</p>
                                                </li>
                                                <li className={'list-group-item'}>
                                                    <h4>待遇・福利厚生</h4>
                                                    <p>月給250,000円〜</p>
                                                    <p>各種資格取得全額支給、家賃補助制度、通勤手当有、資格手当有、労災完備、生活支援制度有</p>
                                                    <p>研修期間３ヶ月～６ヶ月有(左の期間は有期社員となります)</p>
                                                </li>
                                            </ul>
                                        </section>
                                        <div className={'access_button'}>
                                            <Link to={`${process.env.PUBLIC_URL}/form`}><Button bsStyle="warning"> お問い合わせ <Glyphicon glyph={'glyphicon glyphicon-arrow-right'}/></Button></Link>
                                        </div>
                                    </Col>

                                </Row>
                            </div>
                        </div>

                    </section>
                    <div className={"gototop text-right scroll_hidden"} >
                        <button type={'button'} className={'btn btn-primary'} onClick={()=>this.go_info('top_end')}>
                            <Glyphicon glyph="glyphicon glyphicon-chevron-up" /> TOP
                        </button>
                    </div>
                </Grid>

            </div>
        )
    }
}
