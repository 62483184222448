import React from 'react';

import { scroller } from 'react-scroll'

import GetModal from './modal_a'

//todo:bootstrap mod
import {
    Button,
    // ButtonToolbar,
    Grid,
    Row,
    Col,
    Glyphicon,
    Breadcrumb,
    Modal
} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import sanitizeHtml from 'sanitize-html'
import {Form, Text, Select, Option, TextArea} from 'informed'
import request from 'superagent'

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()





export default class RecPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show_form: false,
            show_rec: true,
            form_value: [] ,
            form_touch: [],
            form_error: [
                {foo: 'bar'}
            ],
            // form_error: 1,
            // error_num: 1,
            consented: false,
            // passed: false,
            show: false,
            check: 'false',
            submits: 0,
            complete: false,
            completeContent: ''
        }
    }


    go_info(e) {
        scroller.scrollTo(e, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
            offset: -100
        })
    }

    componentDidMount() {
        this.go_info('top_end')
    }

    updateFormed(value, touch, error, errorNum, submits){
        this.setState({form_value: value})
        this.setState({form_touch: touch})
        this.setState({form_error: error})
        this.setState({submits: submits})


        if(errorNum === 2 && submits > 0 && this.state.consented === true){
            this.setState({check: 'true'})
                this.setState({ show: true });
        }else {
            this.setState({check: 'false'})
        }
    }

    updateConsent(){
        this.setState({consented: true})
    }

    //todo:Modal用
    handleClose() {
        this.setState({ show: false });
    }

    sendApi(){
        request
            .post('http://demo7.sakura.ne.jp/nextone/form_api.php')
            .set('Content-Type','application/json')
            .send(this.state.form_value)
            .end((err, res) => {
                if(err){
                    console.log('送信エラーです')
                    this.setState({completeContent: '送信エラーです。申し訳ありませんがもう一度試してくだい。'})
                    return
                }
                console.log('送信しました！')
                this.setState({completeContent: 'ありがとうございます。送信いたしました。'})
            })

        this.setState({ show: false });
        this.setState({ complete: true });
    }
    completeClose(){
        this.setState({ complete: false });
    }

    render() {

        // console.log('state更新')
        // console.dir(this.state.form_value)
        // console.log('touch更新')
        // console.dir(this.state.form_touch)
        // console.log('error更新')
        // console.dir(this.state.form_error)
        // console.log('submit回数:' + this.state.submits)
        //
        // console.log('モーダルからのprop:' + this.state.consented)
        console.log('送信object:')
        console.dir(this.state.form_value)


        //todo:バリデーション設定
        //todo:入力必須バリデーション
        const simpleRequire = value => {
            return !value ? '選択してください' : null
        }
        const requiredValidation = value => {
            return !value || value.length > 30 ? '入力必須項目です(30文字以下)' : null;
        }
        //todo:mailとmail2が一致するかのバリデーション
        const matchValidation = ( value, values ) => {
            return values.mail !== values.mail2 ? '入力値が一致しません' : null;
        }
        //todo:メルアド書式のバリデーション
        const mailValidation = value => {
            return value.match(/.+@.+\..+/)===null ? 'email書式で入力してください' : null
        }
        //todo:電話番号のバリデーション
        const telValidation = value => {
            var inputNumber = null
            if(value.length > 4){
                inputNumber = phoneUtil.parseAndKeepRawInput(value, 'JP')
                return phoneUtil.isValidNumber(inputNumber) ? null : '正しい番号を入力して下さい'
            }
            return '正しい番号を入力して下さい'
        }


        //todo:maile1フィールドのバリデーション
        const mail1Validation = value => {
            return requiredValidation(value) || mailValidation(value)
        }
        //todo:maile2フィールドのバリデーション
        const mail2Validation = ( value, values ) => {
            return requiredValidation(value) || matchValidation( value, values )
        }
        //todo:お問い合わせ内容フィールドのバリデーション
        const contentValidation = value => {
            return !value || value.length > 500 ? '入力必須項目です(500文字以下)' : null;
        }
        //todo:電話番号フィールドのバリデーション
        const telNumberValid = value => {
            console.log(value)
            return simpleRequire(value) || telValidation(value)
        }




        var openForm = true
        var updateForm = function () {
            openForm = false

        }
        console.log('openForm:' + openForm)

        var inputTel = this.state.form_value.tel
        if (inputTel){
            inputTel = phoneUtil.parseAndKeepRawInput(inputTel, 'JP')
        }


        return (
            <div className={'wrapper'} id={'top_end'}>
                <div className={'background_image'}>&nbsp;</div>
                <Grid id={'main_content'}>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Top</Breadcrumb.Item>
                        <li><Link to={`${process.env.PUBLIC_URL}/rec`}>採用情報</Link></li>
                        <Breadcrumb.Item active>お問い合わせ</Breadcrumb.Item>
                    </Breadcrumb>

                    <section className={'content'}>
                        <div className={'service_content content_area'} name="service_content">
                            <h2 className={'content_title'}>Contact <small>問い合わせ</small></h2>
                            <div className='area_content_box'>
                                <Row>
                                    <Col md={6} mdOffset={3} className={'form_box'}>


                                        <h3 className={'sub_title'}>お問い合わせフォーム</h3>
                                        <Form id="array-form">
                                            {({formState})=> (
                                                <div>
                                                    <hr />
                                                    <span className={'error_text'}>{this.state.form_error.status}</span><br />
                                                    <label className="select_title" htmlFor="select-status">ご用件</label>&nbsp;
                                                    <Select
                                                        field="status"
                                                        id="select-status"
                                                        validate={simpleRequire}
                                                        // validateOnChange
                                                        validateOnBlur
                                                    >
                                                        <Option value="" disabled>
                                                            お選びください
                                                        </Option>
                                                        <Option value="求人への応募">求人へのご応募</Option>
                                                        <Option value="お問い合わせ">お問い合わせ</Option>
                                                    </Select>
                                                    <hr />

                                                    <span className={'error_text'}>{this.state.form_error.name}</span>
                                                    <div className="input-group input-group-sm">
                                                        <label className="input-group-addon" id="sizing-addon1" htmlFor="withFormApi-name">お名前<span>(必須)</span></label>
                                                        <Text
                                                            field="name"
                                                            id="withFormApi-name"
                                                            className="form-control"
                                                            placeholder="お名前"
                                                            aria-describedby="sizing-addon1"
                                                            validate={requiredValidation}
                                                            // validateOnChange
                                                            validateOnBlur
                                                        />
                                                    </div>
                                                    <span className={'error_text'}>{this.state.form_error.furigana}</span>
                                                    <div className="input-group input-group-sm">
                                                        <label className="input-group-addon" id="sizing-addon2" htmlFor="withFormApi-furigana">フリガナ<span>(必須)</span></label>
                                                        <Text
                                                            field="furigana"
                                                            id="withFormApi-furigana"
                                                            className="form-control"
                                                            placeholder="フリガナ"
                                                            aria-describedby="sizing-addon2"
                                                            validate={requiredValidation}
                                                            // validateOnChange
                                                            validateOnBlur
                                                        />
                                                    </div>
                                                    <hr />
                                                    <span className={'error_text'}>{this.state.form_error.mail}</span>
                                                    <div className="input-group input-group-sm">
                                                        <label className="input-group-addon" id="sizing-addon3" htmlFor="withFormApi-mail">e-mail<span>(必須)</span></label>
                                                        <Text
                                                            field="mail"
                                                            id="withFormApi-mail"
                                                            className="form-control"
                                                            placeholder="メールアドレス"
                                                            aria-describedby="sizing-addon3"
                                                            validate={mail1Validation}
                                                            // validateOnChange
                                                            validateOnBlur
                                                        />
                                                    </div>

                                                    <span className={'error_text'}>{this.state.form_error.mail2}</span>
                                                    <div className="input-group input-group-sm">
                                                        <label className="input-group-addon" id="sizing-addon4" htmlFor="withFormApi-mail2">e-mail 再度<span>(必須)</span></label>
                                                        <Text
                                                            field="mail2"
                                                            id="withFormApi-mail2"
                                                            className="form-control"
                                                            placeholder="メールアドレス"
                                                            aria-describedby="sizing-addon4"
                                                            validate={mail2Validation}
                                                            // validateOnChange
                                                            validateOnBlur
                                                        />
                                                    </div>
                                                    <hr />
                                                    <span className={'error_text'}>{this.state.form_error.tel}</span>
                                                    <div className="input-group input-group-sm">
                                                        <label className="input-group-addon" id="sizing-addon5" htmlFor="withFormApi-tel">電話番号<span>(必須)</span></label>
                                                        <Text
                                                            field="tel"
                                                            id="withFormApi-tel"
                                                            className="form-control"
                                                            placeholder="電話番号"
                                                            aria-describedby="sizing-addon5"
                                                            validate={telNumberValid}
                                                            // validateOnChange
                                                            validateOnBlur
                                                        />
                                                    </div>
                                                    <small>※日中連絡のつく番号をご記入ください。</small>
                                                    <hr />

                                                    <span className={'error_text'}>{this.state.form_error.content}</span>
                                                    <div className="input-group input-group-sm">
                                                        <label className="input-group-addon" id="sizing-addon6" htmlFor="textarea-content">お問い合わせ内容<span>(必須)</span></label>
                                                        <TextArea
                                                            field="content"
                                                            id="textarea-content"
                                                            className="form-control"
                                                            aria-describedby="sizing-addon6"
                                                            validate={contentValidation}
                                                            // validateOnChange
                                                            validateOnBlur
                                                        />
                                                    </div>
                                                    <hr />

                                                    <p className={'atenthion'}>プライバシーポリシーの確認・同意</p>
                                                    <GetModal updateConsent={()=>{this.updateConsent()}} />
                                                    {this.state.consented === true ? <p className={'censented'}>確認・同意 済</p> : <p className={'centent'}>上のボタンをクリックして確認して下さい</p>}
                                                    <hr />

                                                    <div className={'submit'}>
                                                        <Button bsStyle={'success'} type="submit" onClick={()=> this.updateFormed(
                                                            formState.values,
                                                            formState.touched,
                                                            formState.errors,
                                                            JSON.stringify(formState.errors).length,
                                                            formState.submits
                                                        )}>確認</Button>
                                                    </div>

                                                    <Modal show={this.state.show} onHide={()=>this.handleClose()}>
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>お問い合わせ内容</Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <ul className={'confirm_list'}>
                                                                <li><strong><Glyphicon glyph={'glyphicon glyphicon-pencil'}/> ご用件</strong>
                                                                    <p>{this.state.form_value.status}</p></li>
                                                                <li><strong><Glyphicon glyph={'glyphicon glyphicon-pencil'}/> お名前</strong>
                                                                    <p>{sanitizeHtml(this.state.form_value.name)}</p></li>
                                                                <li><strong><Glyphicon glyph={'glyphicon glyphicon-pencil'}/> フリガナ</strong>
                                                                    <p>{sanitizeHtml(this.state.form_value.furigana)}</p></li>
                                                                <li><strong><Glyphicon glyph={'glyphicon glyphicon-pencil'}/> email</strong>
                                                                    <p>{this.state.form_value.mail}</p></li>
                                                                <li><strong><Glyphicon glyph={'glyphicon glyphicon-pencil'}/> 電話番号</strong>
                                                                    <p>{inputTel ? phoneUtil.formatInOriginalFormat(inputTel, 'JP') : ''}</p></li>
                                                                <li><strong><Glyphicon glyph={'glyphicon glyphicon-pencil'}/> 内容</strong>
                                                                    <p>{sanitizeHtml(this.state.form_value.content)}</p></li>
                                                            </ul>
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            <Button bsStyle={'warning'} onClick={()=>this.handleClose()}>
                                                               <Glyphicon glyph={'glyphicon glyphicon-pencil'}/> 修正する
                                                            </Button>
                                                            <Button bsStyle={'primary'} onClick={() => this.sendApi()}>
                                                               <Glyphicon glyph={'glyphicon glyphicon-envelope'}/> 送信する
                                                            </Button>
                                                        </Modal.Footer>
                                                    </Modal>

                                                    <Modal show={this.state.complete}>
                                                        <Modal.Header>
                                                            <Modal.Title>
                                                                メールフォーム送信結果
                                                            </Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <p>{this.state.completeContent}</p>
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            <Button bsStyle={'primary'} onClick={()=>this.completeClose()}>
                                                                <Glyphicon glyph={'glyphicon glyphicon-pencil'}/> 閉じる
                                                            </Button>
                                                        </Modal.Footer>
                                                    </Modal>
                                                </div>
                                            )}
                                        </Form>

                                    </Col>{/*end .form_box*/}
                                </Row>
                            </div>
                        </div>

                    </section>
                    <div className={"gototop text-right scroll_hidden"} >
                        <button type={'button'} className={'btn btn-primary'} onClick={()=>this.go_info('top_end')}>
                            <Glyphicon glyph="glyphicon glyphicon-chevron-up" /> TOP
                        </button>
                    </div>
                </Grid>

            </div>
        )
    }
}
