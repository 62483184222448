import React, { Component } from 'react';
import {
    // Router,
    // BrowserRouter,
    BrowserRouter as Router,
    Switch,
    Route,
    // Link,
    // withRouter
} from 'react-router-dom'

import { scroller } from 'react-scroll'

import './App.css';
import Comp1 from './components/index'
import NavBar from './components/nav_bar'
import TopHeader from './components/top_header'
import TopHeaderSp from './components/top_header_sp'
import Footer from './components/footer'

import ServicePage from './components/service_page'
import RecPage from './components/rec_page'
import FormPage from './components/form_page'

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentPosition: 0,
            real_position: 0,
            scrollLimit: 500,
            movingScroll: false,
            location_url: '',
            device: 'pc',
            scrollPoint: ''
        }
    }

    // scrollOn(e){
    //     console.log('スクロール先を受け取った：'+ e)
    // }
    scrollOn(e) {
            console.log('スクロール先を受け取った：'+ e)
        // this.setState({scrollPoint: e})
        scroller.scrollTo('access_content', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
            offset: -100
        })
    }

    componentDidMount() {
        const ua = window.navigator.userAgent.toLowerCase();
        if(ua.indexOf('iphone') > 0 || ua.indexOf('ipad') > 0 || ua.indexOf('android') > 0 && ua.indexOf('mobile') > 0) {
            this.setState({device: 'sp'})
        }else if(ua.indexOf('ipad') > 0 || ua.indexOf('android') > 0) {
            this.setState({device: 'tab'})
        }else  if(ua.indexOf('ipad') > -1 || ua.indexOf('macintosh') > -1 && 'ontouchend' in document) {
            this.setState({device: 'tab'})
        }else {
            this.setState({device: 'pc'})
        }
    }

    render() {
        console.log('デバイスは' + this.state.device)

        const GetPage = () => (
            <Router>
                <div>
                    <Switch>
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
                        <Route path={`${process.env.PUBLIC_URL}/info`} component={Service} />
                        <Route path={`${process.env.PUBLIC_URL}/rec`} component={Rec} />
                        <Route path={`${process.env.PUBLIC_URL}/form`} component={Form} />

                        <Route component={() => (
                            <p>404 NOT FOUND</p>
                        )}/>
                    </Switch>
                </div>
            </Router>
        )
        const Home = (props) => {

            return (
                <div>
                    <NavBar selectPage={props.location.pathname} />
                    {(() => {
                        if(this.state.device === 'sp') {
                            return <TopHeaderSp scrollOn={(e)=>{this.scrollOn(e)}} />
                        }else {
                            return <TopHeader />
                        }
                    })()}
                    <Comp1 scroll_point = {this.state.scrollPoint} />
                </div>
            )
        }

        const Service = (props) => {

            return (
                <div>
                    <NavBar selectPage={props.location.pathname} />
                    <ServicePage/>
                </div>
            )
        }
        const Rec = (props) => {

            return (
                <div>
                    <NavBar selectPage={props.location.pathname} />
                    <RecPage/>
                </div>
            )
        }
        const Form = (props) => {

            return (
                <div>
                    <NavBar selectPage={props.location.pathname} />
                    <FormPage/>
                </div>
            )
        }

        return (
            <div className="App">
                <GetPage/>
                <Footer/>
            </div>
        );
    }
}

export default App;
