import React from 'react';

// import MediaQuery from 'react-responsive'
import { scroller } from 'react-scroll'

import InfoImage from './images/info_image1.jpg'
import ServiceImagePre from './images/service_image_pre.jpg'
import ServiceImageHover from './images/service_image_hover.png'
//import RecImage from './images/rec_image1.jpg'
import RecImagePre from './images/rec_image_pre.jpg'
import RecImageHover from './images/rec_image_hover.png'
import InfoImagePre from './images/info_image_pre.jpg'
import InfoImageHover from './images/info_image_hover.png'

//todo:bootstrap mod
import {
    // Button,
    // ButtonToolbar,
    Grid,
    Row,
    Col,
    Glyphicon,
    // Breadcrumb
} from 'react-bootstrap'

import {Link} from 'react-router-dom'

import Iframe from 'react-iframe'

// import Modal_A from './modal_a'


export default class index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            scrollTop: 0,
            show_service: true,
            show_rec: true,
            show_form: true,
            scroll_p: ''
        }
    }

    changeClass(f){
      //対象にマウスが乗っているかなどの条件を分ける。
        switch(f){

          case 'cont_open01':
          this.setState({isHover_cont01: true})
          break
          //もしclangeclassの値がcont_openならばisHover_cont01は真(true)になる

          case 'cont_close01':
          this.setState({isHover_cont01: false})
          break
          //もしclangeclassの値がcont_closeならばisHover_cont01は真(false)になる

          case 'cont_open02':
          this.setState({isHover_cont02: true})
          break
          //もしclangeclassの値がcont_closeならばisHover_cont01は真(false)になる

          case 'cont_close02':
          this.setState({isHover_cont02: false})
          break
          //もしclangeclassの値がcont_closeならばisHover_cont01は真(false)になる

          case 'cont_open03':
          this.setState({isHover_cont03: true})
          break
          //もしclangeclassの値がcont_closeならばisHover_cont01は真(false)になる

          case 'cont_close03':
          this.setState({isHover_cont03: false})
          break
          //もしclangeclassの値がcont_closeならばisHover_cont01は真(false)になる

          default:
        }
    }



    go_info(e) {
        scroller.scrollTo(e, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
            // containerId: 'ContainerElementID',
            offset: -100
        })
    }

    componentDidMount() {
        this.go_info('top_end')
        if(this.props.scroll_point) {
            this.setState({scroll_p: this.props.scroll_point})
            console.log('子が受取ったスクロール先は：'+ this.props.scroll_point)
            this.go_info(this.state.scroll_p)
        }else {
            console.log('子はスクロール先を受取らなかった')
            this.go_info('top_end')
            // this.go_info('access_content')
        }
    }


    render (props) {
        console.log('スクロール先ステートは'+ this.state.scroll_p)


        return (
            <div className={'wrapper'}>
                <div className={'background_image'}>&nbsp;</div>

                <Grid id={'main_content'}>
                    <section className={'content'}>
                        <div className={'service_content content_area index_content'} name="service_content">
                            <h2 className={'content_title'}>Service <small>業務のご案内</small></h2>
                            <div className='area_content_box'>
                                <Row>
                                    <Col sm={6} className={'text_box'} >
                                        <h4 className={'link_service'}><Link to={`${process.env.PUBLIC_URL}/info`}>詳しい業務内容のご案内 <Glyphicon glyph={'glyphicon glyphicon-arrow-right'}/></Link></h4>
                                    </Col>
                                    <Col sm={6} className='img_box slide_to_r' >
                                        <Link to={`${process.env.PUBLIC_URL}/info`}>
                                          <img onMouseEnter={()=>this.changeClass('cont_open01')} className='pre_image' src={ServiceImagePre} alt={'業務案内のイメージ画像'} />
                                        </Link>
                                        <Link to={`${process.env.PUBLIC_URL}/info`}>
                                          <img onMouseLeave={()=>this.changeClass('cont_close01')} onMouseEnter={()=>this.changeClass('cont_open01')} className={this.state.isHover_cont01 === true ?'hover_image show': 'hover_image show_close'} src={ServiceImageHover} alt={'業務案内のイメージ画像2'} />
                                        </Link>
                                        {/*<p className={'cover_text'}>*/}
                                            {/*<Link to={`${process.env.PUBLIC_URL}/info`}>*/}
                                            {/*組立て・各種請負業務<br />*/}
                                            {/*パチンコ店の遊戯設備工事*/}
                                            {/*</Link>*/}
                                        {/*</p>*/}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className={'access_content content_area index_content'} name="access_content">
                            <h2 className={'content_title'}>Access <small>アクセス</small></h2>
                            <div className='area_content_box'>
                                <Row>
                                    <Col sm={6} className='img_box slide_to_l' >
                                        <div className={'map_box'}>

                                            <Iframe url="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13135.229656760015!2d135.5444275!3d34.6090311!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x79195550972db916!2z5qCq5byP5Lya56S-IE5leHRPbmU!5e0!3m2!1sja!2sjp!4v1591767733934!5m2!1sja!2sjp"
                                                    width="600"
                                                    height="450"
                                                    frameborder="0"
                                                    // style="border:0; position:relative"
                                                    allowfullscreen />
                                        </div>
                                    </Col>
                                    <Col sm={6} className={'text_box'} >
                                        <h4>最寄り駅</h4>
                                        <p>地下鉄谷町線　喜連瓜破駅から徒歩10分</p>
                                        <p>ユニクロ喜連瓜破店さん隣接</p>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className={'rec_content content_area index_content'} name="rec_content">
                            <h2 className={'content_title'}>Recruit <small>採用情報</small></h2>
                            <div className='area_content_box'>
                                <Row>
                                    <Col sm={6} className={'text_box'} >
                                        <p>
                                            高度な専門知識や技術が身につきます。<br />
                                            未経験者でも安心して働ける職場環境です。
                                        </p>
                                        <hr />
                                        <h4 className={'link_service'} >
                                            <Link to={`${process.env.PUBLIC_URL}/rec`}> 採用情報詳細 <Glyphicon glyph={'glyphicon glyphicon-arrow-right'}/></Link>
                                        </h4>
                                        <h4 className={'link_service'} >
                                            <Link to={`${process.env.PUBLIC_URL}/form`}> お問い合わせ先 <Glyphicon glyph={'glyphicon glyphicon-arrow-right'}/></Link>
                                        </h4>
                                    </Col>
                                    <Col sm={6} className='img_box slide_to_r' >
                                    <Link to={`${process.env.PUBLIC_URL}/rec`}>
                                      <img onMouseEnter={()=>this.changeClass('cont_open02')} className='pre_image' src={RecImagePre} alt={'採用情報のイメージ画像'} />
                                    </Link>
                                    <Link to={`${process.env.PUBLIC_URL}/rec`}>
                                      <img onMouseLeave={()=>this.changeClass('cont_close02')} onMouseEnter={()=>this.changeClass('cont_open02')} className={this.state.isHover_cont02 === true ?'hover_image show': 'hover_image show_close'} src={RecImageHover} alt={'採用情報のイメージ画像2'} />
                                    </Link>


                                    </Col>
                                    <i id={'ci'}></i>
                                </Row>
                            </div>
                        </div>
                        <div className={'info_content content_area index_content'} name="info_content">
                            <h2 className={'content_title'}>Information <small>会社概要</small></h2>
                            <div className='area_content_box'>
                                <Row>
                                    <Col sm={6}>
                                        <div className='img_box image_outer slide_to_l' >
                                        <Link to={`${process.env.PUBLIC_URL}/info`}>
                                          <img onMouseEnter={()=>this.changeClass('cont_open03')} className='pre_image' src={InfoImagePre} alt={'会社概要のイメージ画像'} />
                                        </Link>
                                        <Link to={`${process.env.PUBLIC_URL}/info`}>
                                          <img onMouseLeave={()=>this.changeClass('cont_close03')} onMouseEnter={()=>this.changeClass('cont_open03')} className={this.state.isHover_cont03 === true ?'hover_image show': 'hover_image show_close'} src={InfoImageHover} alt={'会社概要のイメージ画像2'} />
                                        </Link>

                                            //<img src={InfoImage} alt={'会社概要イメージ画像'} />
                                        </div>
                                    </Col>
                                    <Col sm={6} className='text_box'>
                                        <ul className={'list-unstyled info_list'} >
                                            <li>会社名 <span>株式会社 NextOne</span></li>
                                            <li>代表者 <span>上條 優雅</span></li>
                                            <li>所在地 <span>大阪市平野区瓜破西1丁目1-1</span></li>
                                            <li>TEL <span>06-6773-9420</span></li>
                                            <li>設立 <span>2018年1月</span></li>
                                            <li>資本金 <span>1000千円(2018年3月)</span></li>
                                            <li>従業員数 <span>14名(2020年6月現在)</span></li>
                                        </ul>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </section>

                    <div className={"gototop text-right scroll_hidden"} >
                        <button type={'button'} className={'btn btn-primary'} onClick={()=>this.go_info('top_end')}>
                            <Glyphicon glyph="glyphicon glyphicon-chevron-up" /> TOP
                        </button>
                    </div>
                </Grid>{/*end .container*/}
            </div>
        )

    }

}
