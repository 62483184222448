import React from 'react';

import { scroller } from 'react-scroll'
import sample_1 from './images/service_sample_1.jpg'
import sample_2 from './images/service_sample_2.jpg'
import sample_3 from './images/service_sample_3.jpg'
import sample_4 from './images/service_sample_4.jpg'
import TaiyoLogo from './images/taiyo_logo.jpg'

import img15397641 from './images/factory/15397641.jpg'
import img45034837 from './images/factory/45034837.jpg'
import img23790753 from './images/denki/23790753.jpg'

import img933530 from './images/pach/933530.jpg'
import img31137717 from './images/pach/31137717.jpg'
import img47492037 from './images/pach/47492037.jpg'

//todo:bootstrap mod
import {
    // Button,
    // ButtonToolbar,
    Grid,
    Row,
    Col,
    Glyphicon,
    Breadcrumb
} from 'react-bootstrap'

import {Link} from 'react-router-dom'

export default class ServicePage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }


    go_info(e) {
        scroller.scrollTo(e, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
            offset: -100
        })
    }

    componentDidMount() {
        this.go_info('top_end')
    }

    render() {

        return (
            <div className={'wrapper'} id={'top_end'}>
                <div className={'background_image'}>&nbsp;</div>
                <Grid id={'main_content'}>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Top</Breadcrumb.Item>
                        <Breadcrumb.Item active>業務のご案内</Breadcrumb.Item>
                    </Breadcrumb>

                    <section className={'content'}>
                        <div className={'service_content content_area'} name="service_content">
                            <h2 className={'content_title'}>Service <small>業務のご案内</small></h2>
                            <div className='area_content_box'>
                                <h3 className={'sub_title'}>組立て・各種請負業務</h3>
                                <Row>
                                    <Col sm={6} className={'img_box info'}>
                                        <img src={img45034837} alt={'会社概要イメージ画像'} />
                                    </Col>
                                    <Col sm={6} className={'img_box info'}>
                                        <img src={img23790753} alt={'会社概要イメージ画像'} />
                                    </Col>
                                </Row>
                            </div>
                            <hr />
                            <div className='area_content_box'>
                                <h3 className={'sub_title'}>パチンコ店の遊戯設備工事</h3>
                                <Row>
                                    <Col sm={6} className={'img_box info'}>
                                        <img src={img47492037} alt={'会社概要イメージ画像'} />
                                    </Col>
                                    <Col sm={6} className={'img_box info'}>
                                        <img src={img933530} alt={'会社概要イメージ画像'} />
                                    </Col>
                                </Row>
                            </div>
                            <div className={'area_content_box'}>
                                <h3>関連会社</h3>
                                {/*<h4 className={'link_go'}>株式会社TAIYO</h4>*/}
                                <h4>
                                    <a href={'http://www.taiyoect.com/company'} target={'_blank'}>
                                    <img src={TaiyoLogo} alt={'株式会社TAIYO'} />
                                    </a>
                                </h4>
                                <a href={'http://www.taiyoect.com/company'} target={'_blank'}>
                               <Glyphicon glyph={'glyphicon glyphicon-globe'} /> http://www.taiyoect.com/company
                                </a>

                            </div>
                        </div>

                    </section>
                    <div className={"gototop text-right scroll_hidden"} >
                        <button type={'button'} className={'btn btn-primary'} onClick={()=>this.go_info('top_end')}>
                            <Glyphicon glyph="glyphicon glyphicon-chevron-up" /> TOP
                        </button>
                    </div>
                </Grid>

            </div>
        )
    }
}