import React from 'react';

import main_logo from './images/main_logo.png'
//todo:bootstrap mod
import {
    // Button,
    // ButtonToolbar,
    Grid,
    Row,
    Col,
    // Glyphicon,
    // Breadcrumb
} from 'react-bootstrap'

export default class Footer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {

        return (
            <footer className={'clearfix'}>
                <Grid>
                    <Row>
                        <Col sm={6}>
                            <a href={'/'}><img className='footer_logo' src={main_logo} alt={'footer logo'} /></a>
                        </Col>
                        <Col sm={6} className='footer_address text-right'>
                            <h4>株式会社 NextOne</h4>
                            <p><small>〒547-0025</small> 大阪市平野区瓜破西1丁目1-1</p>
                        </Col>
                    </Row>
                    <div className={'text-right copyright_text'}>Copyright (C) Next One inc. All Rights Reserved.</div>
                </Grid>
            </footer>
        )
    }
}