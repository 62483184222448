import React from 'react';
import { scroller } from 'react-scroll'
import main_logo from './images/main_logo.png'
// import {
//     Navbar,
//     Nav,
//     NavItem,
//     NavDropdown,
//     MenuItem
// } from 'react-bootstrap'

import {Link} from 'react-router-dom'

export default class NavBar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            sideOpen: false,
            hidden_service: false,
            // change_page: 'changed'
        }
    }
    side_bar_open(e){
        this.setState({sideOpen: e})
    }
    go_info(e) {
        scroller.scrollTo(e, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
            offset: -100
        })
    }

    go_and_close(e) {
        this.go_info(e)
        this.side_bar_open(false)
    }

    goTopPage(e){
        //todo:親app.jsにstateの値を渡す
        //props名'goTop'
        this.props.goTop(this.state.hidden_service)
    }

    // changePage(e) {
    //     this.setState({change_page: e})
    //     this.props.selectMenu(this.state.change_page)
    // }
    componentDidMount(){
        // this.props.selectMenu(this.state.change_page)
    }

    //todo:レンダリングここから
    render () {

        const pageUrl = this.props.selectPage

        var top_class = ''
        var info_class = ''
        var rec_class = ''
        var form_class = ''
        if(pageUrl === '/'){
            top_class = 'active'
        }
        if(pageUrl === '/info'){
            info_class = 'active'
        }
        if(pageUrl === '/rec'){
            rec_class = 'active'
        }
        if(pageUrl === '/form'){
            form_class = 'active'
        }

        //todo:class追加条件
        var classString = 'side_bar';
        if (this.state.sideOpen === true) {
            classString += ' trans_open';
        } else {
            classString = 'side_bar';
        }

        const TopSideNav = () =>{
            if(top_class === 'active') {
                return (
                    <div className={'list_box'}>
                        <h2 className={'side_bar_title'}>Menu List</h2>
                        <ul className={'list-unstyled'}>
                            <li><a className={'btn btn-primary ' + top_class} >Top<span> トップ</span></a></li>
                            <li><Link className={'btn btn-primary ' + info_class} to={`${process.env.PUBLIC_URL}/info`} >Service<span> 業務のご案内</span></Link></li>
                            <li><Link className={'btn btn-primary ' + rec_class} to={`${process.env.PUBLIC_URL}/rec`} >Recruit<span> 採用情報</span></Link></li>
                            <li><a className={'btn btn-primary on_click'} onClick={()=>this.go_info('info_content')} >Information<span> 会社概要</span></a></li>
                            <li><Link className={'btn btn-primary ' + form_class} to={`${process.env.PUBLIC_URL}/form`} >Contact<span> お問い合わせ</span></Link></li>
                        </ul>
                    </div>
                )
            }else {
                return (
                    <div className={'list_box'}>
                        <h2 className={'side_bar_title'}>Menu List</h2>
                        <ul className={'list-unstyled'}>
                            <li><a className={'btn btn-primary ' + top_class} href={'/'} >Top<span> トップ</span></a></li>
                            <li><Link className={'btn btn-primary ' + info_class} to={`${process.env.PUBLIC_URL}/info`} >Service<span> 業務のご案内</span></Link></li>
                            <li><Link className={'btn btn-primary ' + rec_class} to={`${process.env.PUBLIC_URL}/rec`} >Recruit<span> 採用情報</span></Link></li>
                            <li><a className={'btn btn-primary on_click'} href={'/#ci'} >Information<span> 会社概要</span></a></li>
                            <li><Link className={'btn btn-primary ' + form_class} to={`${process.env.PUBLIC_URL}/form`} >Contact<span> お問い合わせ</span></Link></li>
                        </ul>
                    </div>
                )

            }
        }

        const GlobalNav = () => {
            if(top_class === 'active') {
                return (
                    <div id="navbar" className="collapse navbar-collapse">
                        <ul className={'nav navbar-nav'}>
                            <li className={top_class}><a href={'/'}> Top<span> トップ</span></a></li>
                            <li className={info_class}><Link to={`${process.env.PUBLIC_URL}/info`}>service<span> 業務のご案内</span></Link></li>
                            <li className={rec_class}><Link to={`${process.env.PUBLIC_URL}/rec`}> Recruit<span> 採用情報</span></Link></li>
                            <li className={'ci'}><a className={'on_click'} onClick={()=>this.go_info('info_content')}> Information<span> 会社概要</span></a></li>
                            <li className={form_class}><Link to={`${process.env.PUBLIC_URL}/form`}>Contact<span> お問い合わせ</span></Link></li>
                        </ul>
                    </div>
                )
            }else {
                return (
                    <div id="navbar" className="collapse navbar-collapse">
                        <ul className={'nav navbar-nav'}>
                            <li className={top_class}><a href={'/'}> Top<span> トップ</span></a></li>
                            <li className={info_class}><Link to={`${process.env.PUBLIC_URL}/info`}>service<span> 業務のご案内</span></Link></li>
                            <li className={rec_class}><Link to={`${process.env.PUBLIC_URL}/rec`}> Recruit<span> 採用情報</span></Link></li>
                            <li className={'ci'}><a className={'on_click'} href={'/#ci'}> Information<span> 会社概要</span></a></li>
                            <li className={form_class}><Link to={`${process.env.PUBLIC_URL}/form`}>Contact<span> お問い合わせ</span></Link></li>
                        </ul>
                    </div>
                )
            }
        }

        return (
            <nav className="navbar navbar-inverse navbar-fixed-top">
                <div className="navbar-header">
                        <span className={'navbar-brand'}>
                            <a href={'/'}><img className={'nav_logo'} src={main_logo} alt={'nav logo'} /></a>
                        </span>
                </div>
                <div id="navbar" className="collapse navbar-collapse">
                    <GlobalNav/>
                </div>
                {(()=>{
                    if(this.state.sideOpen === false){
                        return (
                            <button className={'side_open open_on'} onClick={()=>this.side_bar_open(true)}>
                                open
                            </button>
                        )
                    } else {
                        return (
                            <button className={'side_open close_on'} onClick={()=>this.side_bar_open(false)}>
                                close
                            </button>
                        )
                    }
                })()}
                <section className={classString}>
                    <TopSideNav/>
                </section>
            </nav>
        )
    }
}